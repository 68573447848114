import LogoBlu from "../../../FemilerCom/Logo/SLogoBlu"
import Logowhit from "../../../FemilerCom/Logo/BLogowhit"
import eleven from "../../../Media/Twindexreport/Eleven.webp"
import twelve from "../../../Media/Twindexreport/Twelve.webp"
import thirteen from "../../../Media/Twindexreport/Thirteen.webp"
import fourteen from "../../../Media/Twindexreport/Fourteen.webp"
import fifteen from "../../../Media/Twindexreport/Fifteen.webp"
import sixteen from "../../../Media/Twindexreport/Sixteen.webp"
import seventeen from "../../../Media/Twindexreport/Seventeen.webp"
import eighteen from "../../../Media/Twindexreport/Eighteen.webp"
import nineteen from "../../../Media/Twindexreport/Nineteen.webp"
import twenty from "../../../Media/Twindexreport/Twenty.webp"
import { repoCon } from "../ReportCom"
import { useContext, useEffect } from "react"
function Report2 ()  {
    let repoConn = useContext(repoCon)
    function sortElementsByClass(containerId, className, appearFirst = true) {
        const containerElement = document.getElementById(containerId);
    
        if (!containerElement) {
            console.error(`Element with ID '${containerId}' not found.`);
            return;
        }
    
        const childElements = Array.from(containerElement.children);
    
        const withClass = [];
        const withoutClass = [];
    
        childElements.forEach((child) => {
            if (child.classList.contains(className)) {
                withClass.push(child);
            } else {
                withoutClass.push(child);
            }
        });
    
        const sortedChildElements = appearFirst ? withClass.concat(withoutClass) : withoutClass.concat(withClass);
    
        containerElement.innerHTML = '';
    
        sortedChildElements.forEach((child) => {
            containerElement.appendChild(child);
        });
    }
    
    useEffect(() => {
        sortElementsByClass("con3", "BackGroundBlackeBold", false )
        sortElementsByClass("con4", "BackGroundBlackSco", true )
        sortElementsByClass("con5", "BackGroundRedBold", false)
        sortElementsByClass("con6", "BackGroundRedSco", true)
        sortElementsByClass("con7", "BackGroundgreeneBold", false)
        sortElementsByClass("con8", "BackGroundgreenSco", true)

        function giveBorderReduce (containerId1, containerId2, containerId3, containerId4, containerId5, containerId6) {

            let getalleleCon3 = document.getElementsByClassName("BackGroundBlackeBold")
            let getalleleCon4 = document.getElementsByClassName("BackGroundBlackSco")
            let getalleleCon5 = document.getElementsByClassName("BackGroundRedBold")
            let getalleleCon6 = document.getElementsByClassName("BackGroundRedSco")
            let getalleleCon7 = document.getElementsByClassName("BackGroundgreeneBold")
            let getalleleCon8 = document.getElementsByClassName("BackGroundgreenSco")
            let putalleletoattaryCon3 = Array.from(getalleleCon3)
            let putalleletoattaryCon4 = Array.from(getalleleCon4)
            let putalleletoattaryCon5 = Array.from(getalleleCon5)
            let putalleletoattaryCon6 = Array.from(getalleleCon6)
            let putalleletoattaryCon7 = Array.from(getalleleCon7)
            let putalleletoattaryCon8 = Array.from(getalleleCon8)
            let con1 = document.getElementById("con3")
            let con2 = document.getElementById("con4")
            let con3 = document.getElementById("con5")
            let con4 = document.getElementById("con6")
            let con5 = document.getElementById("con7")
            let con6 = document.getElementById("con8")
            let conarr1 = Array.from(con1.children)
            let conarr2 = Array.from(con2.children)
            let conarr3 = Array.from(con3.children)
            let conarr4 = Array.from(con4.children)
            let conarr5 = Array.from(con5.children)
            let conarr6 = Array.from(con6.children)

            putalleletoattaryCon3.forEach((ele, index) => {
                if (index === putalleletoattaryCon3.length - 1) {
                    let span = ele.querySelector(".ManyPhraseBoxline");
                    if (span) {
                        ele.removeChild(span);
                    }
                }
                ele.classList.remove("ManyPhraseBordertop");
                if (containerId1 === 'con3' && index === 0) {
                    ele.classList.add("ManyPhraseBordertop");
                }
            });
            putalleletoattaryCon4.forEach((ele, index) => {
                if (index === putalleletoattaryCon4.length - 1) {
                    let span = ele.querySelector(".ManyPhraseBoxlineLast");
                    if (span) {
                        // console.log(span)
                        span.classList.add("display")
                    }
                }
                if (ele.classList.contains("last")){
                    ele.classList.add("ManyPhraseBorderbottom"); 
                }
            });
            putalleletoattaryCon5.forEach((ele, index) => {
                if (index === putalleletoattaryCon5.length - 2) {
                    let span = ele.querySelector(".ManyPhraseBoxlineLast");
                    if (span) {
                        ele.removeChild(span);
                    }
                }
                ele.classList.remove("ManyPhraseBordertop");
                if (containerId3 === 'con5' && index === 0) {
                    ele.classList.add("ManyPhraseBordertop");
                }
            });
            putalleletoattaryCon6.forEach((ele, index) => {
                if (index === putalleletoattaryCon6.length - 1) {
                    let span = ele.querySelector(".ManyPhraseBoxlineLast");
                    if (span) {
                        // console.log(span)
                        span.classList.add("display")
                    }
                }
                if (ele.classList.contains("last")){
                    ele.classList.add("ManyPhraseBorderbottom"); 
                }
            });
            putalleletoattaryCon7.forEach((ele, index) => {
                if (index === putalleletoattaryCon7.length - 1) {
                    let span = ele.querySelector(".ManyPhraseBoxline");
                    if (span) {
                        ele.removeChild(span);
                    }
                }
                ele.classList.remove("ManyPhraseBordertop");
                if (containerId5 === 'con7' && index === 0) {
                    ele.classList.add("ManyPhraseBordertop");
                }
            });
            putalleletoattaryCon8.forEach((ele, index) => {
                if (index === putalleletoattaryCon8.length - 1) {
                    let span = ele.querySelector(".ManyPhraseBoxlineLast");
                    if (span) {
                        console.log(span)
                        span.classList.add("display")
                    }
                }
                if (ele.classList.contains("last")){
                    ele.classList.add("ManyPhraseBorderbottom"); 
                } 
            });
            setTimeout(() => {
                let arr1 = [];
                let arr2 = new Set();
                let arr3 = [];
                let arr4 = new Set();
                let arr5 = [];
                let arr6 = new Set();
                for (let i = 0 ; i < conarr1.length - 1; i++) {
                    arr1.push(conarr1[i].children[0].textContent.trim());
                    for(let j = 0 ; j < conarr2.length; j++) {
                        arr2.add(conarr2[j].children[0].textContent.trim());
                    }
                }
                for (let i = 0 ; i < conarr3.length - 1; i++) {
                    arr3.push(conarr3[i].children[0].textContent.trim());
                    for(let j = 0 ; j < conarr4.length; j++) {
                        arr2.add(conarr4[j].children[0].textContent.trim());
                    }
                }
                for (let i = 0 ; i < conarr5.length - 1; i++) {
                    arr5.push(conarr5[i].children[0].textContent.trim());
                    for(let j = 0 ; j < conarr6.length; j++) {
                        arr2.add(conarr6[j].children[0].textContent.trim());
                    }
                }
                
                // console.log(arr1);
                let arr7 = Array.from(arr2)
                let arr8 = Array.from(arr4)
                let arr9 = Array.from(arr6)
                // console.log(arr3);       
            }, 1000)
        }
        giveBorderReduce("con3", "con4", "con5", "con6", "con7", "con8")
    }, [])

    function Charts (upchart, downchart)  {
        let s = 200 - (upchart + downchart);
        upchart = Math.ceil((upchart + Math.ceil(s*upchart / 100)) / 1.97);
        downchart = Math.ceil((downchart + Math.ceil(s*downchart / 100)) / 1.97);
        return { upchart, downchart };
    }

    let R73 = repoConn.references["R73"];
    let R74 = repoConn.references["R74"];
    let R75 = repoConn.references["R75"];
    let R76 = repoConn.references["R76"];

    let R81 = repoConn.references["R81"];
    let R82 = repoConn.references["R82"];
    let R83 = repoConn.references["R83"];
    let R84 = repoConn.references["R84"];


    const result1 = Charts(R73, R81);
    const result2 = Charts(R74, R82);
    const result3 = Charts(R75, R83);
    const result4 = Charts(R76, R84);
    const SR73 = {
        height: `${result1.upchart}%`,
    };
    const SR74 = {
        height: `${result2.upchart}%`,
    };
    const SR75 = {
        height: `${result3.upchart}%`,
    };
    const SR76 = {
        height: `${result4.upchart}%`,
    };
    const SR81 = {
        height: `${result1.downchart}%`,
    };
    const SR82 = {
        height: `${result2.downchart}%`,
    };
    const SR83 = {
        height: `${result3.downchart}%`,
    };
    const SR84 = {
        height: `${result4.downchart}%`,
    };
    console.log(repoConn)
    return (
        <>
            <div id="Scond">
            <div className='ManyPhraseInSkills-2 pdf-container-3'>
                <img className='ImgReport' src={eleven} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[143]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>                  
                <div className='SmallIntroBatrn'>{repoConn.referencess && repoConn.referencess[144]["Ar content"]}</div>
                <div className="ManyPhraseBox">
                    <div className="con3" id="con3">
                        <div  className={repoConn.answers["121"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "27px"}}>{repoConn.referencess && repoConn.referencess[146]["Ar content"]}</span>
                            <span className={repoConn.answers["121"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["122"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "29px"}}>{repoConn.referencess && repoConn.referencess[147]["Ar content"]}</span>
                            <span className={repoConn.answers["122"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["123"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "26px"}}>{repoConn.referencess && repoConn.referencess[148]["Ar content"]}</span>
                            <span className={repoConn.answers["123"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["124"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "25px"}}>{repoConn.referencess && repoConn.referencess[149]["Ar content"]}</span>
                            <span className={repoConn.answers["124"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["125"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "24px"}}>{repoConn.referencess && repoConn.referencess[150]["Ar content"]}</span>
                            <span className={repoConn.answers["125"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["126"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "23px"}}>{repoConn.referencess && repoConn.referencess[151]["Ar content"]}</span>
                            <span className={repoConn.answers["126"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div  className={repoConn.answers["127"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[152]["Ar content"]}</span>
                            <span className={repoConn.answers["127"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["128"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "17px"}}>{repoConn.referencess && repoConn.referencess[153]["Ar content"]}</span>
                            <span className={repoConn.answers["128"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["129"] == 1  ? "BackGroundBlackeBold mb-0  text-white PhraseText" : "PhraseText "}>
                            <span style={{top: "18px"}}>{repoConn.referencess && repoConn.referencess[154]["Ar content"]}</span>
                            <span className={repoConn.answers["129"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                    </div>
                    <div className="con4" id="con4">
                        <div className={repoConn.answers["121"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[155]["Ar content"]}</span>
                            <span className={repoConn.answers["121"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["122"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[156]["Ar content"]}</span>
                            <span className={repoConn.answers["122"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["123"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[157]["Ar content"]}</span>
                            <span className={repoConn.answers["123"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["124"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[158]["Ar content"]}</span>
                            <span className={repoConn.answers["124"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["125"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[159]["Ar content"]}</span>
                            <span className={repoConn.answers["125"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["126"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[160]["Ar content"]}</span>
                            <span className={repoConn.answers["126"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["127"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[161]["Ar content"]}</span>
                            <span className={repoConn.answers["127"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["128"] == 0  ? "BackGroundBlackSco mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[162]["Ar content"]}</span>
                            <span className={repoConn.answers["128"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["129"] == 0  ? "BackGroundBlackSco last mb-0  text-white PhraseText" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[163]["Ar content"]}</span>
                            <span className={repoConn.answers["129"] == 0 ? "ManyPhraseBoxlineLast" : "ManyPhraseBoxlineLast display"} ></span>
                        </div>
                    </div>
                </div>
                <div className='SmallIntroBatrnBottom'>{repoConn.referencess && repoConn.referencess[145]["Ar content"]}</div>
            </div>
            <div className='Empaty-4Report2 pdf-container-3'>
                <img className='ImgReport' src={twelve} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[164]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>                  
                <h1 className='H1Text'>
                {repoConn.referencess && repoConn.referencess[165]["Ar content"]}
                </h1>
                <div className='BigText'>
                {repoConn.answers["131"] == 1 ? repoConn.referencess && repoConn.referencess[166]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[167]["Ar content"]}
                    <br />
                    {repoConn.answers["132"] == 1 ? repoConn.referencess && repoConn.referencess[168]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[169]["Ar content"]}
                    <br />
                    {repoConn.answers["133"] == 1 ? repoConn.referencess && repoConn.referencess[170]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[171]["Ar content"]}
                    <br />
                    {repoConn.answers["134"] == 1 ? repoConn.referencess && repoConn.referencess[172]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[173]["Ar content"]}
                    <br />
                    {repoConn.answers["135"] == 1 ? repoConn.referencess && repoConn.referencess[174]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[175]["Ar content"]}
                    <br />
                    {repoConn.answers["136"] == 1 ? repoConn.referencess && repoConn.referencess[176]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[177]["Ar content"]}
                    <br />
                    {repoConn.answers["137"] == 1 ? repoConn.referencess && repoConn.referencess[178]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[179]["Ar content"]}
                    <br />
                    {repoConn.answers["138"] == 1 ? repoConn.referencess && repoConn.referencess[180]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[181]["Ar content"]}
                    <br />
                    {repoConn.answers["139"] == 1 ? repoConn.referencess && repoConn.referencess[182]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[183]["Ar content"]}
                    <br />
                </div>
            </div>
            <div className='ManyPhraseInSkills-3 pdf-container-4'>
                <img className='ImgReport' src={thirteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[184]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>                 
                <div className='SmallIntroBatrn'>{repoConn.referencess && repoConn.referencess[185]["Ar content"]}</div>
                <div className="ManyPhraseBox">
                    <div className="con5" id="con5">
                        <div className={repoConn.answers["131"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText  "}>
                            <span style={{top: "24px"}}>{repoConn.referencess && repoConn.referencess[187]["Ar content"]}</span>
                            <span className={repoConn.answers["131"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["132"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText  "}>
                            <span style={{top: "26px"}}>{repoConn.referencess && repoConn.referencess[188]["Ar content"]}</span>
                            <span className={repoConn.answers["132"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["133"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText  "}>
                            <span style={{top: "23px"}}>{repoConn.referencess && repoConn.referencess[189]["Ar content"]}</span>
                            <span className={repoConn.answers["133"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["134"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText  "}>
                            <span style={{top: "18px"}}>{repoConn.referencess && repoConn.referencess[190]["Ar content"]}</span>
                            <span className={repoConn.answers["134"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["135"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[191]["Ar content"]}</span>
                            <span className={repoConn.answers["135"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["136"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[192]["Ar content"]}</span>
                            <span className={repoConn.answers["136"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["137"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[193]["Ar content"]}</span>
                            <span className={repoConn.answers["137"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["138"] == 1 ? "BackGroundRedBold PhraseText  mb-0 con5Child" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[194]["Ar content"]}</span>
                            <span className={repoConn.answers["138"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["139"] == 1 ? "BackGroundRedBold PhraseText con5Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[195]["Ar content"]}</span>
                            <span className={repoConn.answers["139"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                    </div>
                    <div className="con6" id="con6" style={{position: "relative", top: "-6px"}}>
                        <div className={repoConn.answers["131"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText   "}>
                            <span >{repoConn.referencess && repoConn.referencess[196]["Ar content"]}</span>
                            <span className={repoConn.answers["131"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["132"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[197]["Ar content"]}</span>
                            <span className={repoConn.answers["132"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["133"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[198]["Ar content"]}</span>
                            <span className={repoConn.answers["133"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["134"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[199]["Ar content"]}</span>
                            <span className={repoConn.answers["134"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["135"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[200]["Ar content"]}</span>
                            <span className={repoConn.answers["135"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["136"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[201]["Ar content"]}</span>
                            <span className={repoConn.answers["136"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["137"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[202]["Ar content"]}</span>
                            <span className={repoConn.answers["137"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["138"] == 0 ? "PhraseText BackGroundRedSco mb-0 con6Child" : "PhraseText  "}>
                            <span >{repoConn.referencess && repoConn.referencess[203]["Ar content"]}</span>
                            <span className={repoConn.answers["138"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["139"] == 0 ? "PhraseText last BackGroundRedSco mb-0 con6Child" : "PhraseText "}>
                            <span >{repoConn.referencess && repoConn.referencess[204]["Ar content"]}</span>
                            <span className={repoConn.answers["139"] == 0 ? "ManyPhraseBoxlineLast" : "ManyPhraseBoxlineLast display"} ></span>
                        </div>
                    </div>
                </div>
                <div className='SmallIntroBatrnBottom'>{repoConn.referencess && repoConn.referencess[186]["Ar content"]}</div>
            </div>
            <div className='Empaty-5Report2 pdf-container-4'>
                <img className='ImgReport' src={fourteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[205]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>                  
                <h1 className='H1Text'>
                {repoConn.referencess && repoConn.referencess[206]["Ar content"]}
                </h1>
                <div className='BigText'>
                {repoConn.answers["141"] == 1 ? repoConn.referencess && repoConn.referencess[207]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[208]["Ar content"]}
                    <br />
                    {repoConn.answers["142"] == 1 ? repoConn.referencess && repoConn.referencess[209]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[210]["Ar content"]}
                    <br />
                    {repoConn.answers["143"] == 1 ? repoConn.referencess && repoConn.referencess[211]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[212]["Ar content"]}
                    <br />
                    {repoConn.answers["144"] == 1 ? repoConn.referencess && repoConn.referencess[213]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[214]["Ar content"]}
                    <br />
                    {repoConn.answers["145"] == 1 ? repoConn.referencess && repoConn.referencess[215]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[216]["Ar content"]}
                    <br />
                    {repoConn.answers["146"] == 1 ? repoConn.referencess && repoConn.referencess[217]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[218]["Ar content"]}
                    <br />
                    {repoConn.answers["147"] == 1 ? repoConn.referencess && repoConn.referencess[219]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[220]["Ar content"]}
                    <br />
                    {repoConn.answers["148"] == 1 ? repoConn.referencess && repoConn.referencess[221]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[222]["Ar content"]}
                    <br />
                    {repoConn.answers["149"] == 1 ? repoConn.referencess && repoConn.referencess[223]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[224]["Ar content"]}
                    <br />
                </div>
            </div>
            <div className='ManyPhraseInSkills-4 pdf-container-4'>
                <img className='ImgReport' src={fifteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[225]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>    
                <div className='SmallIntroBatrn'>{repoConn.referencess && repoConn.referencess[226]["Ar content"]}</div>
                <div className="ManyPhraseBox">
                    <div className="con7" id="con7">
                        <div className={repoConn.answers["141"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "26px"}}>{repoConn.referencess && repoConn.referencess[228]["Ar content"]}</span>
                            <span className={repoConn.answers["141"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["142"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "27px"}}>{repoConn.referencess && repoConn.referencess[229]["Ar content"]}</span>
                            <span className={repoConn.answers["142"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["143"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "23px"}}>{repoConn.referencess && repoConn.referencess[230]["Ar content"]}</span>
                            <span className={repoConn.answers["143"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["144"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "23px"}}>{repoConn.referencess && repoConn.referencess[231]["Ar content"]}</span>
                            <span className={repoConn.answers["144"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["145"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "23px"}}>{repoConn.referencess && repoConn.referencess[232]["Ar content"]}</span>
                            <span className={repoConn.answers["145"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["146"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "20px"}}>{repoConn.referencess && repoConn.referencess[233]["Ar content"]}</span>
                            <span className={repoConn.answers["146"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["147"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "18px"}}>{repoConn.referencess && repoConn.referencess[234]["Ar content"]}</span>
                            <span className={repoConn.answers["147"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["148"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                            <span style={{top: "15px"}}>{repoConn.referencess && repoConn.referencess[235]["Ar content"]}</span>
                            <span className={repoConn.answers["148"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>

                    <div className={repoConn.answers["149"] == 1 ? "PhraseText BackGroundgreeneBold con7Child" : "PhraseText"}>
                        <span >{repoConn.referencess && repoConn.referencess[236]["Ar content"]}</span>
                            <span className={repoConn.answers["149"] == 1 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                    </div>
                    </div>
                    <div id="con8" style={{position: "relative", top: "-4px"}}>
                        <div className={repoConn.answers["141"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[237]["Ar content"]}</span>
                            <span className={repoConn.answers["141"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["142"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[238]["Ar content"]}</span>
                            <span className={repoConn.answers["142"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["143"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[239]["Ar content"]}</span>
                            <span className={repoConn.answers["143"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["144"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[240]["Ar content"]}</span>
                            <span className={repoConn.answers["144"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["145"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[241]["Ar content"]}</span>
                            <span className={repoConn.answers["145"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["146"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[242]["Ar content"]}</span>
                            <span className={repoConn.answers["146"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["147"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[243]["Ar content"]}</span>
                            <span className={repoConn.answers["147"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["148"] == 0 ? "PhraseText BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[244]["Ar content"]}</span>
                            <span className={repoConn.answers["148"] == 0 ? "ManyPhraseBoxline" : "ManyPhraseBoxline display"} ></span>
                        </div>
                        <div className={repoConn.answers["149"] == 0 ? "PhraseText last BackGroundgreenSco con8Child" : "PhraseText"}>
                            <span >{repoConn.referencess && repoConn.referencess[245]["Ar content"]}</span>
                            <span className={repoConn.answers["149"] == 0 ? "ManyPhraseBoxlineLast" : "ManyPhraseBoxlineLast display"} ></span>
                        </div>
                    </div>
                </div>
                <div className='SmallIntroBatrnBottom'>{repoConn.referencess && repoConn.referencess[227]["Ar content"]}</div>
            </div>
            <div className='ScoCover-2 pdf-container-4'>
                <img className='ImgReport' src={sixteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <Logowhit />
                </div>
                <div className="Text">{repoConn.referencess && repoConn.referencess[246]["Ar content"]}</div>
            </div>
            <div className='BigOneGraph-2 pdf-container-5'>
                <img className='ImgReport' src={seventeen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[247]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>                   
                <div className="Graph-1 ">
                    <div className="TopBox">
                        {/* <div>{repoConn.referencess && repoConn.referencess[248]["Ar content"]}</div> */}
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[249]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[250]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[251]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[252]["Ar content"]}</span>
                        </div>
                        <div>
                            <span>{result4.upchart}%</span>
                            <span>{result3.upchart}%</span>
                            <span>{result2.upchart}%</span>
                            <span>{result1.upchart}%</span>
                        </div>
                    </div>
                    <div className="Graph-Top-1">
                        <div className="BigCanvas" style={SR76}></div>
                        <div className="BigCanvas" style={SR75}></div>
                        <div className="BigCanvas" style={SR74}></div>
                        <div className="BigCanvas" style={SR73}></div>
                    </div>
                    <div className="Graph-Bottom-1">
                        <div className="BigCanvas" style={SR84}></div>
                        <div className="BigCanvas" style={SR83}></div>
                        <div className="BigCanvas" style={SR82}></div>
                        <div className="BigCanvas" style={SR81}></div>
                    </div>
                    <div className="BottomBox">
                        <div>
                            <span>{result4.downchart}%</span>
                            <span>{result3.downchart}%</span>
                            <span>{result2.downchart}%</span>
                            <span>{result1.downchart}%</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[253]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[254]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[255]["Ar content"]}</span>
                            <span>{repoConn.referencess && repoConn.referencess[256]["Ar content"]}</span>
                        </div>
                    </div>
                </div>
                <div className="BottomTitleGraphCon"><div className="BottomTitleGraph">{repoConn.referencess && repoConn.referencess[248]["Ar content"]}</div></div>
            </div>
            <div className='Empaty-6Report2 pdf-container-5'>
                <img className='ImgReport' src={eighteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[257]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>    
                <h1 className='H1Text'>
                {repoConn.referencess && repoConn.referencess[1308]["Ar content"]}
                </h1>
                <div className='BigText'>
                {repoConn.answers["211"] == 1 ? repoConn.referencess && repoConn.referencess[258]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[259]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["212"] == 1 ? repoConn.referencess && repoConn.referencess[260]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[261]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["213"] == 1 ? repoConn.referencess && repoConn.referencess[262]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[263]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["214"] == 1 ? repoConn.referencess && repoConn.referencess[264]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[265]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["215"] == 1 ? repoConn.referencess && repoConn.referencess[266]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[267]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["216"] == 1 ? repoConn.referencess && repoConn.referencess[268]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[269]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["217"] == 1 ? repoConn.referencess && repoConn.referencess[270]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[271]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["218"] == 1 ? repoConn.referencess && repoConn.referencess[272]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[273]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["219"] == 1 ? repoConn.referencess && repoConn.referencess[274]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[275]["Ar content"]}
                    {/* <br /> */}
                </div>
            </div>
            <div className='ManyBox-1Report-2 pdf-container-5'>
                <img className='ImgReport' src={nineteen} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[276]["Ar content"]}</div>
                            </div>
                        </div>
                    </div>    
                <div className="BoxsContaner">
                    <div className="SmallBox">
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[277]["Ar content"]}</span>
                        </div>
                        <div>
                            <span>{repoConn.referencess && repoConn.referencess[287]["Ar content"]}</span>
                        </div>
                    </div>
                    <div className="BigBox">
                        <div className="DetilsBoxs">
                            <span className={repoConn.answers["211"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["211"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[288]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[278]["Ar content"]}</span>
                            <span className={repoConn.answers["211"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["211"] == 1 ? "" : "DisplayNON"}> أهم </span></span>  {/*R85 */}
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["212"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["212"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[289]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[279]["Ar content"]}</span>
                            <span className={repoConn.answers["212"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["212"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["213"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["213"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[290]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[280]["Ar content"]}</span>
                            <span className={repoConn.answers["213"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["213"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["214"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["214"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[291]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[281]["Ar content"]}</span>
                            <span className={repoConn.answers["214"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["214"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["215"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["215"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[292]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[282]["Ar content"]}</span>
                            <span className={repoConn.answers["215"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["215"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["216"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["216"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[293]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[283]["Ar content"]}</span>
                            <span className={repoConn.answers["216"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["216"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["217"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["217"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[294]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[284]["Ar content"]}</span>
                            <span className={repoConn.answers["217"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["217"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["218"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["218"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[295]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[285]["Ar content"]}</span>
                            <span className={repoConn.answers["218"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["218"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                        <div className="DetilsBoxs">
                                                        <span className={repoConn.answers["219"] == 0 ? "ImportBox ManyBoxBlue" :"ImportBox ManyBoxTransparent"}><span className={repoConn.answers["219"] == 0 ? "" : "DisplayNON"}> أهم </span></span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[296]["Ar content"]}</span>
                            <span className="ImportBox">{repoConn.referencess && repoConn.referencess[286]["Ar content"]}</span>
                            <span className={repoConn.answers["219"] == 1 ? "ImportBox ManyBoxBlue" : "ImportBox ManyBoxTransparent"}><span className={repoConn.answers["219"] == 1 ? "" : "DisplayNON"}> أهم </span></span>
                        </div>
                    </div>
                </div>
            </div>
            <div className='Empaty-7Report2 pdf-container-5'>
                <img className='ImgReport' src={twenty} alt="Reportimg" />
                <div className='AsidLogo'>
                    <LogoBlu />
                </div>
                <div className="ContanerForAllStrep">
                        <div className='IntroBatrn'>
                            <div className="StrepCountaner">
                                <div>{repoConn.referencess && repoConn.referencess[297]["Ar content"]}</div>
                            </div>
                        </div>
                    </div> 
                    <h1 className='H1Text'>
                    {repoConn.referencess && repoConn.referencess[1309]["Ar content"]}
                    </h1>   
                <div className='BigText'>
                {repoConn.answers["221"] == 1 ? repoConn.referencess && repoConn.referencess[298]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[299]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["222"] == 1 ? repoConn.referencess && repoConn.referencess[300]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[301]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["223"] == 1 ? repoConn.referencess && repoConn.referencess[302]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[303]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["224"] == 1 ? repoConn.referencess && repoConn.referencess[304]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[305]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["225"] == 1 ? repoConn.referencess && repoConn.referencess[306]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[307]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["226"] == 1 ? repoConn.referencess && repoConn.referencess[308]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[309]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["227"] == 1 ? repoConn.referencess && repoConn.referencess[310]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[311]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["228"] == 1 ? repoConn.referencess && repoConn.referencess[312]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[313]["Ar content"]}
                    {/* <br /> */}
                    {repoConn.answers["229"] == 1 ? repoConn.referencess && repoConn.referencess[314]["Ar content"] 
                    : repoConn.referencess && repoConn.referencess[315]["Ar content"]}
                    {/* <br /> */}
                </div>
            </div>

            </div>
        </>
    )
}

export default Report2